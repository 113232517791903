<template>
  <div>
    <component v-for="(item, key) in components" :key="key" :is="item"/>
  </div>
</template>

<script>
  export default {
    components: {
      Home: () => import('../components/Home')
    },
    data() {
      return {
        components: [
          'Home',
        ]
      }
    },
  }
</script>
